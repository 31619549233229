import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Route, Redirect, Switch } from 'react-router-dom'
import sspf from 'smoothscroll-polyfill'

// import fabric-ui
import { initializeIcons } from '@uifabric/icons'
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar'

// import components
import Header from './components/header/Header'
import LeftSidebar from './components/sidebar/LeftSidebar'
import Footer from './components/footer/Footer'

import Welcome from './components/mainContainer/Welcome'
import ChooseApp from './components/mainContainer/ChooseApp'
import UniTree from './components/mainContainer/unitree/UniTree'
import Importer from './components/mainContainer/Importer'
import Shops from './components/mainContainer/Shops'
import Users from './components/mainContainer/Users'
import Overview from './components/mainContainer/portal/Overview'
import Article from './components/mainContainer/portal/Article'
import Categories from './components/mainContainer/categories/Categories'
import ConfigAm from './components/mainContainer/categories/ConfigAm'
import ConfigCupis from './components/mainContainer/categories/ConfigCupis'
import BrandPriority from './components/mainContainer/brand_priority/BrandPriority'
import FormatGa from './components/mainContainer/format_ga/FormatGa'
import withMainContainer from './components/mainContainer/Extentions/withMainContainer'

// import styles
import 'normalize.css'
import './App.scss'
import './components/mainContainer/MainContainer.scss'

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons('https://res.cdn.office.net/files/fabric/assets/icons/')

// polyfill smoothscroll
sspf.polyfill()

const privateRouteRenderer = (token, Component, props) => {
  if (token !== null) { 
    return (
      <Component {...props} />
    )
  } else {
    return (
      <Redirect to={{
        pathname: '/'
      }}
      />
    )
  }
}

const PrivateRoute = inject('store')(observer(({ store, component: Component, ...rest }) => (
  <Route {...rest} render={props => privateRouteRenderer(store.authStore.token, Component, props)} />
)))

const NotFound = ({ location }) => (
  <div className='default-wrapper'>
    <h2>404 ... for <code>{location.pathname}</code>.</h2>
    <h4>This page cannot not found!</h4>
  </div>
)

@inject('store')
@observer
class App extends Component {
  render () {
    return (
      <div className='ms-Fabric ms-font-m ms-bgColor-neutralLighter app_container'>
        <Header />
        <LeftSidebar />
        <Switch>
          <PrivateRoute exact path='/apps' component={ChooseApp} />
          <PrivateRoute exact path='/unitree/unitrees' component={UniTree} />
          <PrivateRoute exact path='/unitree/import' component={Importer} /> 
          <PrivateRoute exact path='/shops' component={Shops} /> 
          <PrivateRoute exact path='/dashboard/overview' component={Overview} />
          <PrivateRoute exact path='/dashboard/article' component={Article} />
          <PrivateRoute exact path='/users' component={Users} /> 
          <PrivateRoute exact path='/category/categories' component={Categories} />
          <PrivateRoute exact path='/category/import' component={Importer} />
          <PrivateRoute exact path='/category/config_am' component={ConfigAm} />
          <PrivateRoute exact path='/category/config_cupis' component={ConfigCupis} />
          <PrivateRoute exact path='/brand_priority' component={BrandPriority} />
          <PrivateRoute exact path='/brand_priority/import' component={Importer} />
          <PrivateRoute exact path='/format_ga' component={FormatGa} />
          <PrivateRoute exact path='/format_ga/import' component={Importer} />
          <Route exact path='/' component={Welcome} />
          <Route component={withMainContainer(NotFound)} />
        </Switch>
        <Footer />
        {
          this.props.store.appStateStore.show_global_msgBar === true &&
            <MessageBar
              messageBarType={MessageBarType[this.props.store.appStateStore.global_msgBar_type]}
              isMultiline={false}
            >
              {this.props.store.appStateStore.global_msgBar_text}
            </MessageBar>
        }

      </div>
    )
  }
}

export default App
